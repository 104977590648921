document.addEventListener('findstrLoaded', () => {
  const findstr = window.findstr;
  const modalForm = document.querySelector('.hero-search-wrapper__search');
  let firstFocus = true;

  if (findstr && modalForm) {
    findstr.hooks.addAction(
      'searchResults',
      'hero-searches',
      (results, group) => {
        if ('health-guide-hero-search' === group || 'hero-search' === group) {
          if ('' === results.query) {
            modalForm.classList.remove('dropdown-active');
          } else {
            modalForm.classList.add('dropdown-active');
          }
        }
      }
    );

    modalForm.addEventListener('focusin', function () {
      if (firstFocus) {
        firstFocus = false;
      } else {
        modalForm.classList.add('dropdown-active');
      }
    });

    modalForm.addEventListener('focusout', function () {
      modalForm.classList.remove('dropdown-active');
    });
  }

  /**
   *  Build search query based on search value
   */
  findstr.hooks.addFilter(
    'findstrBuildSearchQuery',
    'findstr-search',
    (query, items) => {
      for (const pair of Object.entries(items)) {
        const inputs = pair[1];
        inputs.forEach((input) => {
          if (input.name === 's' || input.name === 'findstr[q]') {
            query.q = input.value;
          }
        });
      }

      return query;
    }
  );
});
